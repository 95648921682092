<template>
  <div class="page page-transport">
    <div class="block-text">
      <h1>A short guide to public transport for large groups</h1>
      <p class="preamble">Bus, subway, railway? Or how about a boat trip?</p>
      <p>No need to choose! In Stockholm, you can have the cake and eat it too.</p>

      <h2>Tickets</h2>
      <p>Stockholm is a cash-free town, so bring your plastic card.</p>

      <h2>Where to get tickets</h2>
      <p>You can buy tickets from <a href="https://sl.se/en/in-english/fares--tickets/" target="_blank">Stockholms Lokaltrafik</a>. Or you can use an app. This guide should contain more information. Please note that Stockholm is cash-free, so bring your plastic card.</p>

      <h2>Where tickets work</h2>
      <p>Buses, boats, subway, …</p>

    <div class="carousel">
      <Carousel />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/icons/Carousel";
export default {
  name: 'MovingAround',
  components: { Carousel },
  data() {
    return {
      activeFilter: null,
    }
  },
  methods: {
    filterItems(e) {
      this.activeFilter = e;
    }
  },
};
</script>

<style scoped>
.carousel {
  position: fixed;
  bottom: 0;
  left: 20px;
  width: 120px;
  height: 120px;
}
</style>
