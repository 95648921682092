<template>
  <div class="page page-explore">

    <!-- Introduction -->
    <div :class="splashVisible ? 'visible' : 'hidden'">
      <div class="instructions">
        <SthlmMap @instructionDone="toggleFarresMap" />
      </div>
    </div>

    <!-- The Game -->
    <transition name="fade">
      <div :class="splashVisible ? 'hidden' : 'visible'">
        <iframe width="100%" height="720" frameborder="0" src="spelet/index2.html"></iframe>
      </div>
    </transition>
  </div>
</template>

<script>
import SthlmMap from '@/components/SthlmMap';

export default {
  name: 'Explore',
  components: {SthlmMap},
  data() {
    return {
      splashVisible: true,
    };
  },
  methods: {
    toggleFarresMap() {
      this.splashVisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
</style>
