<template>
  <div class="page page-fun-facts">
    <div class="block-text">
      <h1>Fun Facts</h1>
      <p class="preamble">Did you know that…? Sharpen your own Stockholm skills, or print the list of questions to entertain your group with.</p>

      <h2 class="centered">Stockholm Quiz</h2>

      <!-- TP-bricka -->
      <div :class="started ? 'tp-bricka small' : 'tp-bricka'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 516.64 516.64">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path class="cls-1" d="M258.32,515.14A256.82,256.82,0,0,1,76.72,76.72a256.82,256.82,0,0,1,363.2,363.2A255.17,255.17,0,0,1,258.32,515.14Z"/>
              <path d="M258.32,3A255.39,255.39,0,0,1,357.7,493.58,255.39,255.39,0,0,1,158.94,23.06,253.74,253.74,0,0,1,258.32,3m0-3C115.65,0,0,115.65,0,258.32S115.65,516.64,258.32,516.64,516.64,401,516.64,258.32,401,0,258.32,0Z"/>
              <g id="pieces">
                <g id="purple">
                  <path class="cls-2" d="M187.93,472.93c-12.74,0-22.21-4.25-26.67-12s-3.4-18,3-29.08l70.58-122.25c6.38-11,14.79-17.11,23.71-17.11s17.33,6.07,23.7,17.11L352.8,431.88c6.37,11,7.42,21.36,3,29.08s-13.93,12-26.67,12Z"/>
                  <path d="M258.52,294c8.35,0,16.31,5.81,22.4,16.36l10.29,17.82,25,43.3,25,43.31,10.29,17.82c6.09,10.55,7.15,20.35,3,27.58s-13.19,11.22-25.37,11.22H187.93c-12.18,0-21.19-4-25.37-11.22s-3.12-17,3-27.58l10.29-17.82,50-86.61,10.29-17.82c6.09-10.55,14-16.36,22.41-16.36h0m0-3c-9.07,0-18.13,6-25,17.86l-10.3,17.82-50,86.61-10.29,17.82c-13.75,23.82-2.5,43.3,25,43.3H329.1c27.5,0,38.75-19.48,25-43.3l-10.29-17.82-50-86.61-10.29-17.82C276.64,297,267.58,291,258.52,291Z"/>
                </g>
                <g id="orange">
                  <path class="cls-3" d="M392.86,447.49c-8.91,0-17.33-6.07-23.7-17.11L298.58,308.13c-6.37-11-7.43-21.36-3-29.08s13.93-12,26.67-12H463.44c12.75,0,22.22,4.25,26.67,12s3.41,18-3,29.08L416.56,430.38C410.19,441.42,401.78,447.49,392.86,447.49Z"/>
                  <path d="M392.86,268.58h70.58c12.19,0,21.2,4,25.37,11.22s3.13,17-3,27.58l-10.3,17.82-7.26,12.58-42.74,74-10.29,17.82c-6.09,10.55-14,16.36-22.4,16.36s-16.31-5.81-22.4-16.36l-10.29-17.82-25-43.3-25-43.31-10.29-17.82c-6.09-10.55-7.15-20.35-3-27.58s13.19-11.22,25.37-11.22h70.58m-50-3H322.28c-27.5,0-38.75,19.48-25,43.3l10.29,17.82,50,86.61,10.29,17.82C374.74,443,383.8,449,392.86,449s18.13-5.95,25-17.86l10.29-17.82,50-86.61,10.29-17.82c13.75-23.82,2.5-43.3-25-43.3H342.86Z"/>
                </g>
                <g id="blue">
                  <path class="cls-4" d="M322.28,249.56c-12.74,0-22.21-4.25-26.67-12s-3.4-18.05,3-29.08L369.16,86.26c6.37-11,14.79-17.11,23.7-17.11s17.33,6.07,23.7,17.11l70.59,122.25c6.37,11,7.42,21.36,3,29.08s-13.92,12-26.67,12Z"/>
                  <path d="M392.86,67.65v3h0c8.35,0,16.3,5.81,22.39,16.36l10.3,17.82,21.05,36.47,28.94,50.13,10.3,17.83c6.09,10.55,7.14,20.35,3,27.58s-13.18,11.22-25.37,11.22H322.28c-12.18,0-21.19-4-25.37-11.22s-3.12-17,3-27.58l10.29-17.83,25-43.3,25-43.3L370.46,87c6.09-10.55,14.05-16.36,22.4-16.36v-3m0,0c-9.06,0-18.12,5.95-25,17.86l-10.29,17.82-50,86.6-10.29,17.83c-13.75,23.82-2.5,43.3,25,43.3H463.44c27.5,0,38.75-19.48,25-43.3l-10.29-17.83-50-86.6L417.86,85.51c-6.87-11.91-15.93-17.87-25-17.86Z"/>
                </g>
                <g id="green">
                  <path class="cls-5" d="M258.52,224.12c-8.92,0-17.33-6.08-23.71-17.11L164.23,84.76c-6.37-11-7.42-21.37-3-29.08s13.93-12,26.67-12H329.1c12.74,0,22.21,4.25,26.67,12s3.4,18-3,29.08L282.22,207C275.85,218,267.43,224.12,258.52,224.12Z"/>
                  <path d="M208.52,42.21v3H329.1c12.18,0,21.19,4,25.37,11.22s3.12,17-3,27.58l-10.29,17.82-25,43.3-25,43.3-10.29,17.83c-6.09,10.55-14.05,16.36-22.4,16.36s-16.31-5.81-22.41-16.36l-10.29-17.83-18.59-32.2-31.41-54.4L165.53,84c-6.09-10.55-7.14-20.35-3-27.58s13.19-11.22,25.37-11.22h20.59v-3m0,0H187.93c-27.5,0-38.75,19.48-25,43.3l10.29,17.82,50,86.6,10.3,17.83c6.87,11.91,15.93,17.86,25,17.86s18.12-6,25-17.86l10.29-17.83,50-86.6L354.1,85.51c13.75-23.82,2.5-43.3-25-43.3H208.52Z"/>
                </g>
                <g id="pink">
                  <path class="cls-6" d="M53.19,249.56c-12.74,0-22.21-4.25-26.67-12s-3.4-18.05,3-29.08L100.08,86.26c6.37-11,14.78-17.11,23.7-17.11s17.33,6.07,23.7,17.11l70.58,122.25c6.37,11,7.43,21.36,3,29.08s-13.93,12-26.67,12Z"/>
                  <path d="M123.78,70.65c8.35,0,16.31,5.81,22.4,16.36l10.29,17.82,10.39,18,39.61,68.6,10.29,17.83c6.09,10.55,7.15,20.35,3,27.58s-13.19,11.22-25.37,11.22H53.19c-12.18,0-21.19-4-25.37-11.22s-3.12-17,3-27.58l10.29-17.83L62,155.26l29.11-50.43L101.37,87c6.1-10.55,14-16.36,22.41-16.36m0-3c-9.07,0-18.13,5.95-25,17.86L88.49,103.33l-50,86.6-10.3,17.83c-13.75,23.82-2.5,43.3,25,43.3H194.36c27.5,0,38.75-19.48,25-43.3l-10.29-17.83-50-86.6L148.78,85.51c-6.88-11.91-15.94-17.86-25-17.86Z"/>
                </g>
                <g id="red">
                  <path class="cls-7" d="M123.78,447.49c-8.92,0-17.33-6.07-23.7-17.11L29.49,308.13c-6.37-11-7.42-21.36-3-29.08s13.93-12,26.67-12H194.36c12.74,0,22.21,4.25,26.67,12s3.4,18-3,29.08L147.48,430.38C141.11,441.42,132.69,447.49,123.78,447.49Z"/>
                  <path d="M123.78,268.58h70.58c12.18,0,21.19,4,25.37,11.22s3.12,17-3,27.58L206.47,325.2l-25,43.31-25,43.3-10.29,17.82c-6.09,10.55-14.05,16.36-22.4,16.36s-16.31-5.81-22.41-16.36L91.08,411.81l-25-43.3-25-43.31L30.79,307.38c-6.09-10.55-7.14-20.35-3-27.58S41,268.58,53.19,268.58h70.59m-50-3H53.19c-27.5,0-38.75,19.48-25,43.3l10.3,17.82,50,86.61,10.29,17.82c6.87,11.91,15.93,17.86,25,17.86s18.12-5.95,25-17.86l10.29-17.82,50-86.61,10.29-17.82c13.75-23.82,2.5-43.3-25-43.3H73.78Z"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <!-- TP-bricka -->

      <button v-if="!started" class="startGame" @click="startGame">Start Game</button>

      <div class="game" v-if="started">
        <div class="question" v-if="!questionAnswered">
          <div class="text" v-if="!questionAnswered">Stockholm's oldest building is from 1270. Which one is it?</div>
          <div class="options" v-if="!questionAnswered">
            <div class="option" @click="checkOption" data-correct="true">The Riddarholmen Church - a Fransiscan Monestary</div>
            <div class="option" @click="checkOption" data-correct="false">Vasamuseet - The house built around the sunken Vasa ship</div>
            <div class="option" @click="checkOption" data-correct="false">The Castle - Where the king and queen live!</div>
          </div>
          <div  v-if="!questionAnswered">Question 1/20</div>
        </div>
        <div class="question pink" v-if="questionAnswered && correct">That's correct! <a href="https://professionals.visitstockholm.com/globalassets/for-professionals/toolbox-pdfer/top-10-attractions-in-stockholm_edit-dec-2020.pdf" target="_blank">Learn more</a><br/><br/>

          OBS! Bara föregående fråga funkar än så länge…<br/><br/>

          <div class="text">Which of these artists is not from Stockholm?</div>
          <div class="options">
            <div class="option">Benny Andersson (member of ABBA)</div>
            <div class="option">Robyn</div>
            <div class="option">José González</div>
          </div>
        </div>
        <div v-if="questionAnswered && !correct">That's not right!<br/> <a href="https://professionals.visitstockholm.com/globalassets/for-professionals/toolbox-pdfer/top-10-attractions-in-stockholm_edit-dec-2020.pdf" target="_blank">Learn more</a><br/> [next question here…]</div>
      </div>

      <p>
        <a
          href="#"
          download="true">Download the quiz PDF</a>
      </p>

    </div>
  </div>
</template>

<script>
import {gsap, Linear} from 'gsap';
export default {
  name: 'FunFacts',
  data() {
    return {
      pieces: [],
      questionAnswered: false,
      correct: false,
      started: false,
    }
  },
  mounted() {
    this.pieces.push(document.querySelectorAll('#pieces g'));
    this.animation();
  },
  methods: {
    animation() {
      const pieces = 6;
      const time = 0.15;
      const duration = time * pieces;
      gsap.to(this.pieces, { opacity: 0.5, ease: Linear.easeNone, duration: duration,
        stagger: {
          each: time,
          repeat: -1,
        }
      });
    },
    startGame() {
      this.started = true;
      gsap.killTweensOf(this.pieces);
      gsap.to(this.pieces, { duration: 1, alpha: 0 });
    },
    checkOption(e) {
      this.correct = e.currentTarget.dataset.correct === "true" ? true : false;

      if (this.correct) {
        gsap.to('#blue', { duration: 1, alpha: 1 });
      }
      this.questionAnswered = true;
    }
  }
};
</script>
<style scoped lang="scss">
button {
  margin: 20px;
}
.startGame {
  margin: 20px auto;
  display: block;
}
.tp-bricka {
  margin: 30px auto;
  transition: all 0.5s;
  &.small {
    max-width: 300px;
  }
}
.cls-1{fill:#fcbf0a;}.cls-2{fill:#5d2b7d;}.cls-3{fill:#c9472d;}.cls-4{fill:#006ebf;}.cls-5{fill:#00837d;}.cls-6{fill:#c40064;}.cls-7{fill:#dd1f26;}
</style>
