<template>
  <div id="app">
    <div class="bg-cover"></div>
    <Menu />
    <MainMenu />
    <div class="wrapper">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <FooterCookies />
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu';
import MainMenu from "@/components/menu/MainMenu";
import FooterCookies from "@/components/FooterCookies";

export default {
  name: 'App',
  components: {
    FooterCookies,
    MainMenu,
    Menu,
  }
}
</script>

<style lang="scss">
  @import 'styles/style.scss';
</style>
