<template>
  <div class="page">
    <h1>About us</h1>
    <p>This is a campaign page of …</p>
    <p>Contact information</p>
  </div>
</template>

<script>
export default {
  name: 'About'
};
</script>

<style scoped>

</style>
