<template>
  <div class="page page-swedish-fika">
    <div class="block-text">
      <h1>Swedish Fika</h1>
      <p class="preamble">Stockholm has some of the world's cosiest fika places.</p>

      <p>Unfamiliar with fika? Check out the video below.</p>

      <iframe width="100%" height="315" src="https://www.youtube.com/embed/oRIeytEXGhQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <p>Can't wait to get to Stockholm? Impress your colleagues with some home baked <a href="#">world-famous cinnamon buns (PDF recipe)</a></p>

      <div class="fika-item">
        <div class="fika-symbol">
          <img src="../assets/icons-fika/dammsugare.png" alt="Dammsugare" />
        </div>
        <div class="fika-name">Dammsugare (The Vacuum Cleaner)</div>
        <div class="fika-desc">The Dammsugare, also known as the punsch-roll (punschrulle) is a small, cylindrical pastry covered in green marzipan. The ends are dipped in chocolate. The contents are crushed biscuits, butter, cocoa and punsch liqueur. The name stems from the supposed practice of making it; collecting yesterday's crumbs for the filling.</div>
        <div class="download-link">Download recipe (PDF)</div>
      </div>

      <div class="fika-item">
        <div class="fika-symbol">
          <img src="../assets/icons-fika/cinnamon-bun.png" alt="Cinnamon bun" />
        </div>
        <div class="fika-name">Kanelbulle (Cinnamon roll)</div>
        <div class="fika-desc">There's no indulgence quite as good as a freshly baked cinnamon bun. In Sweden, we even have a special day for the Cinnamon Bun - October 4th - "Kanelbulledagen". You can make a quick version, but we've also excluded a version so good, the Swedish Sin is no match for it.</div>
        <div class="download-link">Download recipe (PDF)</div>
      </div>

      <div class="fika-item">
        <div class="fika-symbol">
          <img src="../assets/icons-fika/chocolate-ball.png" alt="Chocolate ball" />
        </div>
        <div class="fika-name">Chokladboll (Chocolate ball)</div>
        <div class="fika-desc">In a hurry? This is the best and simplest fika when your afternoon dip is low.</div>
        <div class="download-link">Download recipe (PDF)</div>
      </div>

      <div class="fika-item">
        <div class="fika-symbol">
          <img src="../assets/icons-fika/semla.png" alt="Semla" />
        </div>
        <div class="fika-name">Semla</div>
        <div class="fika-desc">A description of how delicious and weird the Semla is.</div>
        <div class="download-link">Download recipe (PDF)</div>
      </div>

      <div class="fika-item">
        <div class="fika-symbol">
          <img src="../assets/icons-fika/lussekatt.png" alt="Lussekatt" />
        </div>
        <div class="fika-name">Lussekatt (saffron bun)</div>
        <div class="fika-desc">Christmas saffron bun description and some following interesting facts.</div>
        <div class="download-link">Download recipe (PDF)</div>
      </div>

      <h2>Looking for a shortcut?</h2>
      <p>Try some of the famous cafés below:</p>

      <ul class="cafe-list">
        <li><span>Vete-Katten</span> - Perhaps Stockholm's oldest and most famous café? Founded in 1928 (Kungsgatan 55, Norrmalm)</li>
        <li><span>Café Petissan</span> - Classic Swedish fika in the middle of idyllic Skansen (Skansen, Djurgården)</li>
        <li><span>Gildas rum</span> - Like visiting grandma (Skånegatan 79, Södermalm)</li>
        <li><span>Ritorno Konditori</span> - Experience Stockholm as it was in 1930 (Odengatan 80, Vasastan)</li>
        <li><span>Saturnus</span> - Literally the biggest kanelbulle you can get in town (Eriksbergsgatan 6, Östermalm)</li>
        <li><span>Svensk Tenn Tea Room</span> - Classic Swedish design and fika (Strandvägen 5, Östermalm)</li>
      </ul>

    </div>

    <div class="decor coffee-cup">
      <img src="../assets/icons-fika/fikakopp.png" alt="Coffee cup illustration" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwedishFika'
};
</script>