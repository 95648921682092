<template>
  <nav class="navigation primary-nav" role="navigation" ref="primaryMenu">
    <div class="menu-wrap">
      <div :class="className" @click="toggleOpen">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul :class="menuOpenClass">
        <li><router-link to="/">Explore</router-link></li>
        <li><router-link to="/walk-the-talk">Walk the talk</router-link></li>
        <li><router-link to="/moving-around">Moving around</router-link></li>
        <li><router-link to="/fun-facts">Fun Facts</router-link></li>
        <li><router-link to="/swedish-fika">Swedish Fika</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MainMenu',
  data() {
    return {
      menuOpen: false,
      primaryMenu: null,
      primaryMenuHeight: 0,
    }
  },
  mounted() {
    this.primaryMenu = this.$refs.primaryMenu;
    this.primaryMenuHeight = this.$refs.primaryMenu.clientHeight;
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    className() {
      return `hamburger ${this.menuOpen ? 'closed' : 'open'}`;
    },
    menuOpenClass() {
      return this.menuOpen ? 'open' : 'closed';
    }
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 100) {
        this.primaryMenu.classList.add('shrunk');
      } else {
        this.primaryMenu.classList.remove('shrunk');
      }
    },
    toggleOpen() {
      this.menuOpen = !this.menuOpen;
    }
  },
};
</script>

<style scoped>

</style>