<template>
  <div class="footer cookies" v-if="!cookiesAccepted">
    <div class="wrap">
      <div class="text">
        In Sweden, we have a famous cookbook called “7 types of cookies”. We also have cookies on this site. And just as with the cookbook, we continuously improve based on user feedback.<br/>
        <button @click="acceptCookies">Accept Cookies</button>
      </div>
      <div class="img">
        <img src="../assets/cookie.png" class="cookie" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCookies",
  data() {
    return {
      cookiesAccepted: false,
    }
  },
  mounted() {
    if (window.localStorage && window.localStorage.getItem('cookiesAccepted') !== null) {
      this.cookiesAccepted = true;
    }
  },
  methods: {
    acceptCookies() {
      window.localStorage.setItem('cookiesAccepted', true);
      this.cookiesAccepted = true;
    },
  }
};
</script>

<style scoped>

</style>
