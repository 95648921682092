<template>
  <nav class="navigation secondary-nav" role="navigation">
<!--    <div class="sthlm-logo">
      <img src="../assets/logo.svg" alt="Stockholm logo" />
    </div>-->
    <ul>
      <li><router-link to="/about">About us</router-link></li>
      <li>
        <a href="https://professionals.visitstockholm.com/" target="_blank">Main website</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
}
</script>
