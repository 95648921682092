<template>
  <div class="page page-distances">
    <div class="block-text">
      <h1>Top 10 reasons why Stockholm is better</h1>
      <p class="preamble">Stockholm is called The Capital of Scandinavia for a reason.</p>
      <ol class="ten-facts">
        <li><span>World-class facilities</span><br/>
          With a wide selection of arenas & conference venues and 37,300 hotel rooms, Stockholm is equipped to host any large event. Top shelf technology at the venues and free, high-speed Wi-Fi in the hotels is the norm.
        </li>
        <li><span>A sustainable city</span><br/>
          We don't only have great minds like Greta, we're following Greta's message. Just look at all these mind-blowing facts!
          <ul>
            <li>100% of the energy used by the City of Stockholm´s own operations comes from renewable sources.</li>
            <li>99% of Stockholm´s solid waste is recycled</li>
            <li>96% of the hotels in Stockholm has an environmental certification</li>
            <li>Stockholm has one of the cleanest (and tastiest) tap waters in the world. No need to buy bottled water.</li>
            <li>Stockholm has 1/3 green area, 1/3 water, 1/3 built area</li>
            <li>Stockholm Arlanda Airport has a ISO14001 certification</li>
            <li>Stockholm has a goal to be a fossil-fuel free city by 2040</li>
          </ul>
        </li>
        <li>
          <span>Closer than you think</span><br>
          Stockholm is little more than a two-hour flight away from major European cities like London and Paris. With direct flight connections from 160 destinations, getting to Stockholm Arlanda Airport couldn’t be easier. From there, it’s just a short hop into the city center.
        </li>

        <li>
          <span>Efficient and reliable</span><br/>
          Sweden is one of the best-organized countries in the world, and the Swedes have a reputation for being friendly and reliable. Most people speak English fluently which makes all contacts very easy. Hosting a conference or organizing an event in Stockholm is glitch-free and offers high quality at a fair price.
        </li>

        <li>
          <span>Creative and trendy</span><br>
          Art, architecture and design play an integrated part of Stockholm city life. Music, food and fashion are at the cutting edge and the shopping is as good, or better than, even the largest capitals. And thanks to successful startups like Spotify, Skype and King, Stockholm has become o global tech- and startup hub.
        </li>

        <li>
          <span>Culture and history</span>
        </li>

        <li>
          <span>Culinary delights</span>
        </li>

        <li>
          <span>City of Science</span>
        </li>

        <li>
          <span>The Stockholm Archipelago</span>
        </li>

        <li>
          <span>Everything within easy reach</span>
        </li>
      </ol>

      <a href="https://professionals.visitstockholm.com/why-stockholm/" target="_blank">Länk</a>
    </div>

    <div class="decor boat">
      <img src="../assets/boat_1.svg" width="100" id="distances-boat" class="boat1" />
      <img src="../assets/sailboat.svg" width="100" id="distances-sailboat" class="boat2" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: 'Distances',
  mounted() {
    this.animateBoat();
  },
  methods: {
    animateBoat() {
      gsap.to('#distances-boat', { rotate: -5, duration: 3, repeat: -1, yoyo: true });
      gsap.to('#distances-sailboat', { rotate: -5, duration: 2, y: 10, repeat: -1, yoyo: true });
      gsap.fromTo('#distances-boat', { x: '100vw' }, { duration: 240, x: '-100%', repeat: -1, repeatDelay: 2 });
      gsap.fromTo('#distances-sailboat', { x: '100vw' }, { duration: 340, x: '-100%', repeat: -1, repeatDelay: 2, delay: 8 });
    }
  },
};
</script>