import Vue from 'vue';
import VueRouter from 'vue-router';
import Explore from '@/views/Explore';
import Distances from '@/views/Distances';
import MovingAround from '@/views/MovingAround';
import FunFacts from '@/views/FunFacts';
import SwedishFika from '@/views/SwedishFika';
import About from '@/views/About';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Explore',
    component: Explore,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/walk-the-talk',
    name: 'Distances',
    component: Distances,
  },
  {
    path: '/moving-around',
    name: 'Moving Around',
    component: MovingAround,
  },
  {
    path: '/fun-facts',
    name: 'Fun Facts',
    component: FunFacts,
  },
  {
    path: '/swedish-fika',
    name: 'Swedish Fika',
    component: SwedishFika,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = `${to.name} | SITE NAME` || 'SITE NAME';
  });
});

export default router;
